import React, { useEffect, useState, useContext } from 'react';
import styled, { css } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../contexts/UserContext';
import useSizeDetector from '../hooks/useSizeDetector';
import ApprovalStatus from '../constants/ApprovalStatus';
import RentalStatus from '../constants/RentalStatus';
import BoardSlug from '../constants/BoardSlug';
import UnitType from '../constants/UnitType';
import BookingCalendarModal from '../components/modal/BookingCalendarModal';
import RoomLocationGuideModal from '../custom/modal/RoomLocationGuideModal';

import BannerImg from '../custom/images/banner.png';
import MyStatusBackgroundImg from '../custom/images/myStatusBackground.png';
import MyStatusBackgroundMobileImg from '../custom/images/myStatusBackground_mobile.png';
import LoginIconImg from '../custom/images/login.svg';
import MypageIconImg from '../custom/images/mypage.svg';
import PlusIconImg from '../custom/images/plus.svg';
import Row1BackgroundImg from '../custom/images/row1Background.png';
import Row2BackgroundImg from '../custom/images/row2Background.png';
import Row1_1Img from '../custom/images/row1-1.png';
import Row1_2Img from '../custom/images/row1-2.png';
import Row2_1Img from '../custom/images/row2-1.svg';
import Row2_2Img from '../custom/images/row2-2.svg';
import Row2_3Img from '../custom/images/row2-3.svg';
import Row2_4Img from '../custom/images/row2-4.svg';

import * as api from '../apis';

const Main = () => {
	const navigate = useNavigate();
	const { user } = useContext(UserContext);
	const { isDesktop, Desktop, Mobile } = useSizeDetector();

	const [noticeBoard, setNoticeBoard] = useState(null);
	const [postList, setPostList] = useState(null);
	const [bookingList, setBookingList] = useState(null);
	const [bookingCalendarModalData, setBookingCalendarModalData] = useState({ show: false });
	const [roomLocationGuideModalData, setRoomLocationGuideModalData] = useState({ show: false });

	useEffect(() => {
		if (user) {
			let filterParams = [`page_size=2`];
			api.getMyBooking(filterParams).then((res) => {
				if (res && res.data) {
					setBookingList(res.data);
				}
			});
		}

		api.getBoards().then((res) => {
			if (res && res.data) {
				let noticeBoard = res.data.find((board) => board.slug === BoardSlug.NOTICE);
				setNoticeBoard(noticeBoard);
				api.getPosts(noticeBoard.id, [`page_size=4`]).then((res) => {
					if (res && res.data) {
						setPostList(res.data.items);
					}
				});
			}
		});
	}, [user]);

	const onClickLogin = () => {
		navigate('/login');
	};

	const onClickMypage = () => {
		navigate('/mypage');
	};

	const onClickNoticeBoard = () => {
		navigate('/boards/notice');
	};

	const onClickPostContents = (postId) => {
		navigate(`/boards/${noticeBoard.id}/posts/${postId}`);
	};

	const onClickBooking = (type) => {
		if (!user) {
			navigate('/login');
			return;
		}
		navigate(`/booking`, {
			state: {
				unit_type: type,
			},
		});
	};

	const onClickRoomUnitBooking = (unitId) => {
		if (!user) {
			navigate('/login');
			return;
		}
		navigate(`/booking/schedule/${unitId}`);
	};

	const onClickProductUnitBooking = (unitId) => {
		if (!user) {
			navigate('/login');
			return;
		}
		navigate(`/booking/product/schedule/${unitId}`);
	};

	const renderBoardContentsRow = (post, idx) => {
		return (
			<BoardContentsRow key={idx} onClick={() => onClickPostContents(post.id)}>
				<BoardCategory type={`category-${post.category?.id ?? 0}`}>
					<BoardCategoryText>{post.category?.name ?? '-'}</BoardCategoryText>
				</BoardCategory>
				<BoardContentTitle>{post.title}</BoardContentTitle>
				<BoardContentDate>
					{post.created_at ? new Date(post.created_at).toLocaleDateString() : '-'}
				</BoardContentDate>
				<BoardContentsHover>
					<PlusButtonIcon src={PlusIconImg} />
				</BoardContentsHover>
			</BoardContentsRow>
		);
	};

	const renderMyBooking = () => {
		if (!bookingList || bookingList.items.length === 0) {
			return <NoBookingList>예약 내역이 없습니다.</NoBookingList>;
		}
		return (
			<MyBookingContainer>
				{bookingList?.items.map((booking, idx) => {
					const bookingUnitType = booking.unit.unit_type_name;
					const locationDetail =
						bookingUnitType === UnitType.ROOM
							? `${booking.unit.name} ${booking.room_item?.name}`
							: `${booking.unit.name}`;
					return renderBookingDetailRow(
						idx,
						bookingUnitType,
						booking.approval_status,
						booking.rental_status,
						booking.booking_date.split('~')[0],
						locationDetail
					);
				})}
			</MyBookingContainer>
		);
	};

	const renderBookingDetailRow = (idx, type, approvalStatus, rentalStatus, calendarDetail, locationDetail) => {
		return (
			<MyContentsRow key={idx}>
				<BookingStatusBox status={approvalStatus}>
					<span>{ApprovalStatus.toString(approvalStatus)}</span>
				</BookingStatusBox>
				<BookingDetailContainer>
					<BookingDetailRow>
						<BookingType type={type}>{UnitType.toString(type)}</BookingType>
						{type === UnitType.PRODUCT && (
							<RentalStatusBox status={rentalStatus}>
								{RentalStatus.toString(rentalStatus)}
							</RentalStatusBox>
						)}
					</BookingDetailRow>
					<BookingDetailRow column>
						<BookingDetailText>{calendarDetail ?? '-'}</BookingDetailText>
						<BookingLocationDetailText>{locationDetail ?? '-'}</BookingLocationDetailText>
					</BookingDetailRow>
				</BookingDetailContainer>
			</MyContentsRow>
		);
	};

	return (
		<Container>
			<BannerBackground src={BannerImg}>
				<BannerContainer>
					<BoardContainer isLogin={user}>
						<BoardTopContainer>
							<BoardTopComponent>
								<BoardTitle>Notice</BoardTitle>
								<BoardSeparator />
								<BoardSubTitle>공지사항</BoardSubTitle>
							</BoardTopComponent>
							<BoardTopComponent>
								<BoardMore onClick={() => onClickNoticeBoard()}>+</BoardMore>
							</BoardTopComponent>
						</BoardTopContainer>
						<BoardContentsContainer>
							{postList?.map((post, idx) => {
								return renderBoardContentsRow(post, idx);
							})}
						</BoardContentsContainer>
					</BoardContainer>
					<MyContainer src={isDesktop ? MyStatusBackgroundImg : MyStatusBackgroundMobileImg}>
						<MyTopContainer>
							{user ? (
								<>
									<MyStatusTitle>내 예약현황</MyStatusTitle>
									{renderMyBooking()}
								</>
							) : (
								<>
									<MyStatusTitle>내 예약현황</MyStatusTitle>
									<MyStatusLoginInfo>로그인 후 이용해 주세요.</MyStatusLoginInfo>
								</>
							)}
						</MyTopContainer>
						<MyBottomContainer>
							{user ? (
								<BottomButtonContainer onClick={onClickMypage}>
									<BottomText>마이페이지</BottomText>
									<BottomButtonIconContainer>
										<BottomButtonIcon src={MypageIconImg} />
									</BottomButtonIconContainer>
								</BottomButtonContainer>
							) : (
								<BottomButtonContainer onClick={onClickLogin}>
									<BottomText>로그인</BottomText>
									<BottomButtonIconContainer>
										<BottomButtonIcon src={LoginIconImg} />
									</BottomButtonIconContainer>
								</BottomButtonContainer>
							)}
						</MyBottomContainer>
						{user && (
							<MyBottomButtonContainer>
								<MyBookingCalendarButton onClick={() => setBookingCalendarModalData({ show: true })}>
									나의 예약 캘린더
								</MyBookingCalendarButton>
								<RoomLocationGuideButton onClick={() => setRoomLocationGuideModalData({ show: true })}>
									스튜디오 위치 안내도
								</RoomLocationGuideButton>
							</MyBottomButtonContainer>
						)}
					</MyContainer>
				</BannerContainer>
			</BannerBackground>
			<BodyContainer>
				<BodyContainerRow>
					<BodyRowLeft background={Row1BackgroundImg} backgroundColor={'#22499d'}>
						<BodyLeftTitle>시설예약</BodyLeftTitle>
						<BodyLeftMore onClick={() => onClickBooking(UnitType.ROOM)}>
							<BodyLeftMoreText>바로가기</BodyLeftMoreText>
							<BodyLeftMoreButton row={1}>+</BodyLeftMoreButton>
						</BodyLeftMore>
					</BodyRowLeft>
					<BodyRowRight row={1}>
						<BodyRow1Item background={Row1_1Img} onClick={() => onClickRoomUnitBooking(1)}>
							<Row1ItemHover />
						</BodyRow1Item>
						<BodyRow1Item background={Row1_2Img} onClick={() => onClickRoomUnitBooking(2)}>
							<Row1ItemHover />
						</BodyRow1Item>
					</BodyRowRight>
				</BodyContainerRow>
				<BodyContainerRow>
					<BodyRowLeft background={Row2BackgroundImg} backgroundColor={'#00b1a6'}>
						<BodyLeftTitle>장비예약</BodyLeftTitle>
						<BodyLeftMore onClick={() => onClickBooking(UnitType.PRODUCT)}>
							<BodyLeftMoreText>바로가기</BodyLeftMoreText>
							<BodyLeftMoreButton row={2}>+</BodyLeftMoreButton>
						</BodyLeftMore>
					</BodyRowLeft>
					<BodyRowRight row={2}>
						<BodyRow2Item onClick={() => onClickProductUnitBooking(3)}>
							<Row2ItemText>카메라</Row2ItemText>
							<Row2ItemIconContainer>
								<Row2ItemIcon src={Row2_1Img} />
							</Row2ItemIconContainer>
							<Row2ItemHover />
						</BodyRow2Item>
						<BodyRow2Item onClick={() => onClickProductUnitBooking(4)}>
							<Row2ItemText>웹캠</Row2ItemText>
							<Row2ItemIconContainer>
								<Row2ItemIcon src={Row2_2Img} />
							</Row2ItemIconContainer>
							<Row2ItemHover />
						</BodyRow2Item>
						<BodyRow2Item onClick={() => onClickProductUnitBooking(5)}>
							<Row2ItemText>무선마이크</Row2ItemText>
							<Row2ItemIconContainer>
								<Row2ItemIcon src={Row2_3Img} />
							</Row2ItemIconContainer>
							<Row2ItemHover />
						</BodyRow2Item>
						<BodyRow2Item onClick={() => onClickProductUnitBooking(6)}>
							<Row2ItemText>노트북</Row2ItemText>
							<Row2ItemIconContainer>
								<Row2ItemIcon src={Row2_4Img} />
							</Row2ItemIconContainer>
							<Row2ItemHover />
						</BodyRow2Item>
					</BodyRowRight>
				</BodyContainerRow>
			</BodyContainer>
			{bookingCalendarModalData.show && (
				<BookingCalendarModal onClose={() => setBookingCalendarModalData({ show: false })} />
			)}
			{roomLocationGuideModalData.show && (
				<RoomLocationGuideModal
					onClose={() => setRoomLocationGuideModalData({ show: false })}
				></RoomLocationGuideModal>
			)}
		</Container>
	);
};

const Container = styled.div`
	width: 100%;
	height: 100%;
`;

const BannerBackground = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;

	position: relative;
	width: 100%;
	height: 576px;
	top: 0px;

	${(props) =>
		props.src &&
		css`
			background-image: url(${props.src});
			background-repeat: no-repeat;
			background-size: cover;
		`}

	@media only screen and (max-width: 767.98px) {
		width: 100%;
		height: auto;
	}
`;

const BannerContainer = styled.div`
	width: 100%;
	height: 100%;
	max-width: 1240px;

	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: row;

	@media only screen and (max-width: 767.98px) {
		flex-direction: column-reverse;
		justify-content: flex-end;
		padding: 20px;
	}
`;

const BoardContainer = styled.div`
	width: 925px;

	@media only screen and (max-width: 767.98px) {
		width: 100%;
		margin-top: 11px;

		${(props) =>
			props.isLogin &&
			css`
				margin-top: 75px;
			`}
	}
`;

const BoardTopContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const BoardTopComponent = styled.div`
	display: flex;
	align-items: center;
`;

const BoardTitle = styled.span`
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 600;
	font-size: 42px;
	line-height: 50px;
	color: #ffffff;

	@media only screen and (max-width: 767.98px) {
		font-size: 34px;
		line-height: 41px;
	}
`;

const BoardSeparator = styled.div`
	width: 1px;
	height: 20px;
	border: 1px solid #ffffff;
	margin: 10px 17px 0px 17px;

	@media only screen and (max-width: 767.98px) {
		height: 14px;
		margin: 10px 10px 0px 10px;
	}
`;

const BoardSubTitle = styled.span`
	margin-top: 10px;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 600;
	font-size: 24px;
	line-height: 29px;
	color: #ffffff;

	@media only screen and (max-width: 767.98px) {
		font-size: 16px;
		line-height: 19px;
	}
`;

const BoardMore = styled.div`
	width: 35px;
	height: 35px;

	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #00b1a6;
	cursor: pointer;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 400;
	font-size: 32px;
	line-height: 32px;
	color: #ffffff;

	@media only screen and (max-width: 767.98px) {
		width: 25px;
		height: 25px;
		font-size: 24px;
		line-height: 24px;
		padding-bottom: 3px;
	}
`;

const BoardContentsContainer = styled.div`
	margin-top: 10px;
	width: 925px;
	height: 318px;
	background-color: rgba(0, 0, 0, 0.4);
	display: flex;
	padding: 40px;
	box-sizing: border-box;

	@media only screen and (max-width: 767.98px) {
		width: 100%;
		height: auto;
		padding: 20px;
		flex-direction: column;
	}
`;

const BoardContentsHover = styled.div`
	position: absolute;
	bottom: -22px;
	left: 78px;
	width: 43px;
	height: 43px;

	display: none;
	align-items: center;
	justify-content: center;
	border-radius: 21px;
	background-color: #00b1a6;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 300;
	font-size: 48px;
	line-height: 48px;
	color: #ffffff;
`;

const PlusButtonIcon = styled.img`
	width: 24px;
	height: 24px;
	object-fit: contain;

	@media only screen and (max-width: 767.98px) {
		width: 24px;
		height: 24px;
		object-fit: contain;
	}
`;

const BoardContentsRow = styled.div`
	position: relative;
	width: 200px;
	height: 237px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	flex-shrink: 0;
	padding: 20px 15px;
	box-sizing: border-box;
	cursor: pointer;
	border: 6px solid transparent;

	& + & {
		margin-left: 15px;
	}

	&:hover {
		border: 6px solid rgba(220, 220, 220, 0.8);
	}
	&:hover > ${BoardContentsHover} {
		display: flex;
	}

	@media only screen and (max-width: 767.98px) {
		width: 100%;
		height: auto;
		padding: 0;
		border: none;

		& + & {
			margin-left: 0;
			margin-top: 10px;
		}

		&:hover {
			border: 6px solid transparent;
		}
		&:hover > ${BoardContentsHover} {
			display: none;
		}
	}
`;

const BoardCategory = styled.div`
	width: 66px;
	height: 24px;

	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 25px;
	padding: 0 10px;

	${(props) => {
		switch (props.type) {
			case 'category-1':
				return css`
					background-color: #22499d;
				`;
			case 'category-2':
				return css`
					background-color: #00b1a6;
				`;
			case 'category-3':
				return css`
					background-color: #e33d65;
				`;
			default:
				return css`
					background-color: #22499d;
				`;
		}
	}}
`;

const BoardCategoryText = styled.span`
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 600;
	font-size: 13px;
	line-height: 16px;
	color: #ffffff;

	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const BoardContentTitle = styled.span`
	height: 110px;
	margin-top: 10px;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 28px;
	color: #ffffff;
	overflow: hidden;
	cursor: pointer;

	@media only screen and (max-width: 767.98px) {
		width: 100%;
		height: auto;
		margin-top: 5px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
`;

const BoardContentDate = styled.span`
	margin-top: 15px;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	color: #ffffff;

	@media only screen and (max-width: 767.98px) {
		margin-top: 1px;
	}
`;

const MyContainer = styled.div`
	width: 295px;
	height: 382px;
	margin-top: 7px;
	margin-left: 20px;
	box-sizing: border-box;
	${(props) =>
		props.src &&
		css`
			background-image: url(${props.src});
			background-size: contain;
			background-repeat: no-repeat;
		`}

	@media only screen and (max-width: 767.98px) {
		width: 100%;
		height: calc((100vw - 40px) * (320 / 280));
		flex-shrink: 0;
		margin: 0;
	}
`;

const MyTopContainer = styled.div`
	width: 100%;
	height: 312px;
	padding-left: 38px;
	display: flex;
	flex-direction: column;
	justify-content: center;

	@media only screen and (max-width: 767.98px) {
		height: calc(100% - ((100vw - 40px) * (51 / 280)));
		padding-left: 20px;
	}
`;

const NoBookingList = styled.div`
	margin-top: 20px;
	display: flex;
	width: 100%;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 19px;
	color: #ffffff;
`;

const MyBookingContainer = styled.div`
	margin-top: 40px;
	margin-bottom: 20px;

	@media only screen and (max-width: 767.98px) {
		margin-top: 25px;
	}
`;

const MyContentsRow = styled.div`
	height: 73px;
	display: flex;

	& + & {
		margin-top: 15px;
	}
`;

const BookingStatusBox = styled.div`
	width: 73px;
	height: 73px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 36px;
	border: 7px solid rgba(255, 255, 255, 0.8);

	span {
		width: 30px;
		text-align: center;
		font-family: 'Pretendard';
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 19px;
		color: #ffffff;
	}
`;

const BookingDetailContainer = styled.div`
	margin-left: 20px;
	display: flex;
	flex-direction: column;
	flex: 1;
	justify-content: center;
`;

const BookingDetailRow = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;

	& + & {
		margin-top: 9px;
	}

	${(props) =>
		props.column &&
		css`
			flex-direction: column;
			align-items: flex-start;
		`}
`;

const BookingType = styled.div`
	width: 49px;
	height: 26px;
	border-radius: 50px;
	display: flex;
	align-items: center;
	justify-content: center;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 17px;

	${(props) => {
		switch (props.type) {
			case UnitType.ROOM:
				return css`
					border: 1px solid #fff7d6;
					color: #fff7d6;
				`;
			case UnitType.PRODUCT:
				return css`
					border: 1px solid #85d9ff;
					color: #85d9ff;
				`;
			default:
				return css`
					border: 1px solid #fff7d6;
					color: #fff7d6;
				`;
		}
	}};
`;

const RentalStatusBox = styled.div`
	margin-left: 5px;
	width: 70px;
	height: 26px;
	border-radius: 50px;
	display: flex;
	align-items: center;
	justify-content: center;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 17px;
	color: #ffffff;

	${(props) => {
		switch (props.status) {
			case RentalStatus.RENTAL_READY:
				return css`
					background-color: #00c6ba;
				`;
			case RentalStatus.RENTAL_DELAY:
				return css`
					background-color: #e7683f;
				`;
			case RentalStatus.RETURN_DELAY:
				return css`
					background-color: #ff3535;
				`;
			case RentalStatus.RENTAL_COMPLETE:
			case RentalStatus.RETURN_COMPLETE:
				return css`
					background-color: #0d93f4;
				`;
			default:
				return css`
					background-color: #00c6ba;
				`;
		}
	}};
`;

const BookingDetailText = styled.span`
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 17px;
	color: #ffffff;
`;

const BookingLocationDetailText = styled.span`
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 19px;
	color: #ffffff;

	width: 150px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const MyBottomContainer = styled.div`
	width: 100%;
	height: 65px;
	padding-left: 38px;

	@media only screen and (max-width: 767.98px) {
		height: calc((100vw - 40px) * (51 / 280));
		padding-left: 0;
	}
`;

const MyStatusTitle = styled.span`
	margin-top: 50px;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 32px;
	line-height: 38px;
	color: #ffffff;

	@media only screen and (max-width: 767.98px) {
		margin-top: 0;
		font-size: 24px;
		line-height: 29px;
	}
`;

const MyStatusLoginInfo = styled.span`
	margin-top: 15px;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	color: #ffffff;

	@media only screen and (max-width: 767.98px) {
		margin-top: 0;
		font-size: 14px;
		line-height: 17px;
	}
`;

const BottomButtonContainer = styled.div`
	cursor: pointer;
	width: 257px;
	height: 100%;
	padding-left: 29px;
	display: flex;
	align-items: center;

	@media only screen and (max-width: 767.98px) {
		width: calc((100vw - 40px) * (280 / 280));
		padding-left: 0;
	}
`;

const BottomText = styled.span`
	width: 158px;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 600;
	font-size: 24px;
	line-height: 29px;
	color: #ffffff;

	@media only screen and (max-width: 767.98px) {
		text-align: center;
		width: calc((100vw - 40px) * (231 / 280));
		font-size: 20px;
		line-height: 24px;
	}
`;

const BottomButtonIconContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	@media only screen and (max-width: 767.98px) {
		width: calc((100vw - 40px) * (49 / 280));
	}
`;

const BottomButtonIcon = styled.img`
	margin-left: 20px;

	@media only screen and (max-width: 767.98px) {
		margin-left: 0;
		width: 22px;
		height: 22px;
		object-fit: contain;
	}
`;

const MyBottomButtonContainer = styled.div`
	width: calc(100% - 4px);
	margin-top: 20px;
	display: flex;
	justify-content: space-between;
	gap: 10px;
`;

const MyBottomButton = styled.button`
	padding: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #00b1a6;
	outline: none;
	border: none;
	border-radius: 5px;
	color: #ffffff;
	font-weight: 600;
	font-size: 15px;
	white-space: nowrap;
	cursor: pointer;

	@media only screen and (max-width: 767.98px) {
		flex: 1 1 auto;
	}
`;

const MyBookingCalendarButton = styled(MyBottomButton)`
	background-color: #00b1a6;
`;

const RoomLocationGuideButton = styled(MyBottomButton)`
	background-color: #609ee9;
`;

const BodyContainer = styled.div`
	width: 100%;
`;

const BodyContainerRow = styled.div`
	display: flex;
	height: 260px;

	@media only screen and (max-width: 767.98px) {
		height: auto;
		flex-direction: column;
	}
`;

const BodyRowLeft = styled.div`
	flex-shrink: 0;
	width: 549px;
	height: 100%;
	${(props) =>
		props.background &&
		css`
			background-image: url(${props.background});
		`};
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;

	@media only screen and (max-width: 767.98px) {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		height: 66px;
		padding: 20px;
		background-image: none;
		background-color: ${(props) => props.backgroundColor};
	}
`;

const BodyLeftTitle = styled.div`
	padding-top: 59px;
	padding-right: 90px;
	text-align: right;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 600;
	font-size: 26px;
	line-height: 31px;
	color: #ffffff;

	@media only screen and (max-width: 767.98px) {
		padding: 0;
		font-size: 22px;
		line-height: 26px;
	}
`;

const BodyLeftMore = styled.div`
	margin-top: 13px;
	padding-right: 90px;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	cursor: pointer;

	@media only screen and (max-width: 767.98px) {
		margin: 0;
		padding: 0;
	}
`;

const BodyLeftMoreText = styled.span`
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	color: #ffffff;

	@media only screen and (max-width: 767.98px) {
		font-size: 14px;
		line-height: 17px;
	}
`;

const BodyLeftMoreButton = styled.div`
	margin-left: 8px;
	width: 28px;
	height: 28px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 14px;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 300;
	font-size: 28px;
	line-height: 28px;
	color: #ffffff;
	padding-bottom: 5px;

	${(props) => {
		switch (props.row) {
			case 1:
				return css`
					background-color: #00b1a6;
				`;
			case 2:
				return css`
					background-color: #22499d;
				`;
			default:
				return css`
					background-color: #00b1a6;
				`;
		}
	}};

	@media only screen and (max-width: 767.98px) {
		width: 25px;
		height: 25px;
	}
`;

const BodyRowRight = styled.div`
	display: flex;
	align-items: center;
	flex-grow: 1;
	${(props) => {
		switch (props.row) {
			case 1:
				return css`
					background-color: #ffffff;
				`;
			case 2:
				return css`
					background-color: #f1f3f6;
				`;
			default:
				return css`
					background-color: #ffffff;
				`;
		}
	}};

	@media only screen and (max-width: 767.98px) {
		flex-direction: column;
		${(props) =>
			props.row === 2 &&
			css`
				padding: 20px;
			`};
	}
`;

const Row1ItemHover = styled.div`
	display: none;
	position: absolute;
	left: 0;
	top: 0;
	width: 493px;
	height: 206px;
	border: 10px solid #00b1a6;
`;

const BodyRow1Item = styled.div`
	position: relative;
	margin-left: 30px;
	width: 493px;
	height: 206px;
	${(props) =>
		props.background &&
		css`
			background-image: url(${props.background});
		`};
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	cursor: pointer;

	& + & {
		margin-left: 14px;
	}

	&:hover > ${Row1ItemHover} {
		display: flex;
	}

	@media only screen and (max-width: 767.98px) {
		width: 100%;
		height: calc((100vw - 40px) * (134 / 280));
		margin: 0;

		& + & {
			margin: 0;
		}

		&:hover > ${Row1ItemHover} {
			display: none;
		}
	}
`;

const Row2ItemText = styled.div`
	padding-top: 32px;
	padding-left: 30px;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 600;
	font-size: 22px;
	line-height: 26px;
	color: #000000;

	@media only screen and (max-width: 767.98px) {
		padding: 0;
		font-size: 20px;
		line-height: 24px;
	}
`;

const Row2ItemIconContainer = styled.div`
	@media only screen and (max-width: 767.98px) {
		width: 44px;
	}
`;

const Row2ItemIcon = styled.img`
	margin-top: 68px;
	margin-left: 156px;

	@media only screen and (max-width: 767.98px) {
		margin: 0;
		width: 100%;
	}
`;

const Row2ItemHover = styled.div`
	display: none;
	position: absolute;
	left: 0;
	top: 0;
	width: 240px;
	height: 200px;
	border: 10px solid #375fb6;
`;

const BodyRow2Item = styled.div`
	position: relative;
	margin-left: 30px;
	width: 240px;
	height: 200px;
	background-color: #ffffff;
	cursor: pointer;

	& + & {
		margin-left: 13px;
	}
	&:hover > ${Row2ItemText} {
		color: #22499d;
	}
	&:hover > ${Row2ItemHover} {
		display: flex;
	}

	@media only screen and (max-width: 767.98px) {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 30px;
		width: 100%;
		height: 90px;
		margin: 0;

		& + & {
			margin-left: 0;
			margin-top: 10px;
		}

		&:hover > ${Row2ItemText} {
			color: #000000;
		}
		&:hover > ${Row2ItemHover} {
			display: none;
		}
	}
`;

export default Main;
