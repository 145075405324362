import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { UserContext } from '../contexts/UserContext';
import CustomResource from '../custom/resource';

import * as api from '../apis';

import LoginIdIconImg from '../assets/images/screens/Login/login_id.svg';
import LoginPasswordIconImg from '../assets/images/screens/Login/login_password.svg';

const Login = () => {
	const navigate = useNavigate();
	const { user, login } = useContext(UserContext);
	const [userLogin, setUserLogin] = useState('');
	const [password, setPassword] = useState('');

	useEffect(() => {
		if (user) {
			loginCompleted();
		}
	}, [user]);

	const onClickLogin = async () => {
		try {
			const res = await api.login(userLogin, password);
			if (res && res.data) {
				login(res.data);
				loginCompleted();
			}
		} catch (err) {
			console.error(err);
		}
	};

	const onKeyPressInput = (e) => {
		if (e.key === 'Enter') {
			onClickLogin();
		}
	};

	const loginCompleted = () => {
		navigate('/');
	};

	const onClickSSOLogin = () => {
		console.log('onClickSSOLogin');

		// SSO Login URL 실제 연결 필요
		// window.location =
		// 	'https://www.rec.ac.kr/xn-sso/gw.php?login_type=standalone&site=studio&callback_url=http%3A%2F%2Fstudio.rec.ac.kr%3A3000%2Flogin%2Fcallback';
	};

	return (
		<Container>
			<Title>로그인</Title>
			<LoginWrapper>
				<LoginTitle>일반로그인</LoginTitle>
				<LoginInputContainer>
					<Input
						type="text"
						value={userLogin}
						onChange={(e) => setUserLogin(e.currentTarget.value)}
						onKeyPress={onKeyPressInput}
						placeholder="아이디"
					/>
					<LoginIdIcon src={LoginIdIconImg} />
				</LoginInputContainer>
				<LoginInputContainer>
					<Input
						type="password"
						value={password}
						onChange={(e) => setPassword(e.currentTarget.value)}
						onKeyPress={onKeyPressInput}
						placeholder="패스워드"
					/>
					<LoginPasswordIcon src={LoginPasswordIconImg} />
				</LoginInputContainer>
				<Button onClick={onClickLogin}>로그인</Button>
			</LoginWrapper>
			<LoginWrapper>
				<LoginTitle>통합로그인</LoginTitle>
				<SsoLoginButtonContainer>
					<Button type="sso" onClick={onClickSSOLogin}>
						통합로그인
					</Button>
					<LoginDescription>{CustomResource.sso_login}</LoginDescription>
				</SsoLoginButtonContainer>
			</LoginWrapper>
		</Container>
	);
};

const Container = styled.div`
	width: 380px;
	margin-top: 50px;
	margin-bottom: 87px;
	padding: 60px 40px 30px 40px;
	background-color: #ffffff;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	@media only screen and (max-width: 767.98px) {
		width: calc(100% - 20px);
		margin: 10px 10px 50px 10px;
		height: auto;
		padding: 30px 20px 21px 20px;
	}
`;

const Title = styled.span`
	margin-bottom: 30px;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 700;
	font-size: 36px;
	line-height: 43px;
	color: #000000;

	@media only screen and (max-width: 767.98px) {
		margin-bottom: 10px;
		font-size: 32px;
		line-height: 38px;
	}
`;

const LoginWrapper = styled.div`
	width: 100%;
	padding-top: 30px;
	padding-bottom: 30px;

	display: flex;
	flex-direction: column;
	align-items: center;

	&:last-child {
		border-top: 1px solid #cccccc;
	}
`;

const LoginTitle = styled.div`
	margin-bottom: 20px;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 700;
	font-size: 24px;
	line-height: 29px;
	color: #333333;
`;

const LoginInputContainer = styled.div`
	position: relative;
	width: 100%;

	& + & {
		margin-top: 10px;
		margin-bottom: 10px;
	}
`;

const LoginIdIcon = styled.img`
	width: 22px;
	height: 22px;
	position: absolute;
	top: 9px;
	left: 10px;
`;

const LoginPasswordIcon = styled.img`
	width: 22px;
	height: 22px;
	position: absolute;
	top: 9px;
	left: 10px;
`;

const LoginDescription = styled.div`
	margin-top: 10px;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #333333;
	white-space: pre-line;
`;

const SsoLoginButtonContainer = styled.div`
	width: 100%;

	display: flex;
	flex-direction: column;
	align-items: center;

	@media only screen and (max-width: 767.98px) {
		margin-top: 0px;
	}
`;

const Input = styled.input`
	height: 40px;
	width: 100%;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	text-align: center;
	color: #666666;

	background: #ffffff;
	border: 1px solid #dddddd;
	border-radius: 5px;
`;

const Button = styled.div`
	width: 100%;
	height: 50px;

	background-color: #22499d;
	border-radius: 5px;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 700;
	font-size: 18px;
	line-height: 22px;

	display: flex;
	align-items: center;
	justify-content: center;
	color: #ffffff;
	cursor: pointer;

	&:hover {
		background-color: #0c438d;
	}

	${(props) =>
		props.type === 'sso' &&
		css`
			background-color: #00b1a6;

			&:hover {
				background-color: #008f87;
			}
		`}

	@media only screen and (max-width: 767.98px) {
		margin-top: 0;
	}
`;

export default Login;
